// export const paymentDetails={
//     gpay:"fcbizopiskn@freecharge",
//     paytm:"fcbizopiskn@freecharge"
// }
// Batham

// export const paymentDetails={
//     gpay:"fcbizf1zwou@freecharge",
//     paytm:"fcbizf1zwou@freecharge"
// }
//Manojnewfc


// export const paymentDetails={
//     gpay:"fcbiznrvpxh@freecharge",
//     paytm:"fcbiznrvpxh@freecharge"
// }
// Shivam 

export const paymentDetails={
    gpay:"ycfltmm72k63@idbi",
    paytm:"ycfltmm72k63@idbi"
}

// export const paymentDetails={
//     gpay:"fcbizsvjgun@freecharge",
//     paytm:"fcbizsvjgun@freecharge"
// }